import { Selector } from "@rendering/plasma";

export type GetCanvasArgs = {
  cimDocId?: string;
  selector: Selector;
  overprint?: string;
};

export const createGetCanvas = () => {
  const canvasCache: { [key: string]: HTMLCanvasElement } = {};

  return ({ cimDocId, selector, overprint }: GetCanvasArgs): HTMLCanvasElement => {
    const cacheKey = `${cimDocId}-${JSON.stringify(selector)}-${overprint}`;
    if (!canvasCache[cacheKey]) {
      canvasCache[cacheKey] = document.createElement("canvas");
      canvasCache[cacheKey].id = `fusion-${cacheKey}`;
    }
    return canvasCache[cacheKey];
  };
};
