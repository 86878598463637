// Reset canvasses to release memory
// https://pqina.nl/blog/total-canvas-memory-use-exceeds-the-maximum-limit/
// Width and height has to be 1, not 0 for this to work
export function releaseCanvasses(canvasses: HTMLCanvasElement[]) {
  canvasses.forEach((canvas) => {
    canvas.width = 1;
    canvas.height = 1;
    const ctx = canvas.getContext("2d");
    ctx && ctx.clearRect(0, 0, 1, 1);
  });
}
